import React from "react";
import { connect } from "react-redux";
import { userLogin } from "react-admin";
import { GoogleLogin } from "react-google-login";

const styles = {
  flex: { display: "flex", alignItems: "center", justifyContent: "center" },
};

function GoogleLoginForm(props) {
  const googleResponse = (response) => {
    props.userLogin({ tokenId: response.tokenId });
  };

  return (
    <div style={styles.flex}>
      <p>
        <GoogleLogin
          clientId={
            "740115115122-lq9g0li9s102662tvnrge7mi1h5hqtas.apps.googleusercontent.com"
          }
          buttonText="Google Login"
          onSuccess={googleResponse}
          onFailure={googleResponse}
        />
      </p>
    </div>
  );
}

export default connect(undefined, { userLogin })(GoogleLoginForm);
