// in posts.js
import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  Button,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useRecordContext,
} from "react-admin";
import { EditButton, Toolbar, SaveButton, DeleteButton } from "react-admin";
import IconRepeat from "@material-ui/icons/Repeat";
import { withStyles } from '@material-ui/core';
export const OrganizationIcon = IconRepeat;

const SwitchButton = ({
  className,
  source,
  record = {},
  emptyText,
  ...rest
}) => {
  const _onClick = () => {
    localStorage.setItem("organization.id", record.id);
    localStorage.setItem("organization.name", record.name);
    console.log(record);
    window.location.reload();
    return false;
  };
  return (
    <Button onClick={_onClick} label="Switch">
      <IconRepeat />
    </Button>
  );
};

const ConditionalSaveButton = (props) => {
  const record = useRecordContext();
  const userId = localStorage.getItem("userId");

  if(!record) {
    return null;
  }

  if (record.users && (record.ownerId === userId || record.users.some(e => e.id === userId && e.role === "Admin"))) {
    return <SaveButton {...props}/>;
  }

  return null;
}

const ConditionalDeleteButton = (props) => {
  const record = useRecordContext();
  const userId = localStorage.getItem("userId");

  if(!record) {
    return null;
  }

  if (record.users && (record.ownerId === userId || record.users.some(e => e.id === userId && e.role === "Admin"))) {
    return <DeleteButton {...props}/>;
  }

  return null;
}

export const OrganizationList = (props) => (
  <List title="Organizations" {...props} bulkActionButtons={false}>
    <Datagrid>
      <SwitchButton />
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
      <EditButton/>
    </Datagrid>
  </List>
);

const OrganizationTitle = ({ record }) => {
  return <span>Editing organization {record ? `"${record.name}"` : ""}</span>;
};

const toolbarStyles = {
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
};


const UserEditToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props} >
      <ConditionalSaveButton />
      <ConditionalDeleteButton/>
  </Toolbar>
));

export const OrganizationEdit = (props) => (
  <Edit title={<OrganizationTitle />} {...props}>
    <SimpleForm toolbar={<UserEditToolbar/>}>
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth />
      <ArrayInput source="users">
        <SimpleFormIterator>
          <TextInput source="user" label="User" />
          <SelectInput
            source="role"
            label="Role"
            initialValue={"Contributor"}
            choices={[
              { id: "Admin", name: "Admin" },
              { id: "Contributor", name: "Contributor" },
            ]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const OrganizationCreate = (props) => (
  <Create title="Create an Organization" {...props}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth />
    </SimpleForm>
  </Create>
);
