import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "react-admin";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  const organization = localStorage.getItem("organization.id");
  options.headers.set("Authorization", `Bearer ${token}`);

  if (organization && organization !== "null") {
    options.headers.set("Organization", organization);
  }

  return fetchUtils.fetchJson(url, options);
};

export default jsonServerProvider("https://api.jsonstorage.net/v1", httpClient);
