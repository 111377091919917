// in posts.js
import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, TextField, TextInput, SelectInput, SelectField } from 'react-admin';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import { useRecordContext } from 'react-admin';

export const IntentIcon = CallToActionIcon;

export const IntentList = (props) => (
    <List title="Intents" {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Name" />
            <SelectField source="rule" choices={[
                { id: 0, name: 'Query String' },
                { id: 1, name: 'Header' },
                { id: 2, name: 'IP Address' },
                { id: 3, name: 'Country' }
            ]} />
        </Datagrid>
    </List>
);

const IntentTitle = ({ record }) => {
    return <span>Editing Intent {record ? `"${record.name}"` : ''}</span>;
};

const ConditionalParameterField = () => {
    const record = useRecordContext();
    return record && (record.rule === 0 || record.rule === 1) ? <TextInput source="parameter" label="Parameter" /> : null;
}

export const IntentEdit = (props) => (
    <Edit title={<IntentTitle />} {...props}>
        <SimpleForm>
            <TextInput source="name" fullWidth/>
            <ConditionalParameterField/>
            <SelectField source="rule" choices={[
                { id: 0, name: 'Query String' },
                { id: 1, name: 'Header' },
                { id: 2, name: 'IP Address' },
                { id: 3, name: 'Country' }
            ]} />
            <SelectInput source="operator" choices={[
                { id: 0, name: 'Equals' },
                { id: 1, name: 'Does not equal' },
                { id: 2, name: 'Contains' },
                { id: 3, name: 'Does not contain' }
            ]} />
            <TextInput source="value" label="Value" />
        </SimpleForm>
    </Edit>
);

export const IntentCreate = (props) => (
    <Create title="Create an Intent" {...props}>
        <SimpleForm>
            <TextInput source="name" fullWidth/>         
            <SelectInput source="rule" choices={[
                { id: 0, name: 'Query String' },
                { id: 1, name: 'Header' },
                { id: 2, name: 'IP Address' },
                { id: 3, name: 'Country' }
            ]} />
        </SimpleForm>
    </Create>
);