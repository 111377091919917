// in posts.js
import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
} from "react-admin";
import BookIcon from "@material-ui/icons/VpnKey";
export const ApiKeyIcon = BookIcon;

export const ApiKeyList = (props) => (
  <List title="Api Keys" {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Name" />
      <BooleanField source="read" label="Read items" />
      <BooleanField source="create" label="Create items " />
      <BooleanField source="modify" label="Modify items " />
      <BooleanField source="delete" label="Delete items" />
    </Datagrid>
  </List>
);

const ApiKeyTitle = ({ record }) => {
  return <span>Editing Api key {record ? `"${record.name}"` : ""}</span>;
};

export const ApiKeyEdit = (props) => (
  <Edit title={<ApiKeyTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <BooleanInput
        fullWidth
        source="read"
        label="Read items - allows to access non-public items via API using this API key."
      />
      <BooleanInput
        fullWidth
        source="create"
        label="Create items - allows to create new items via API using this API key."
      />
      <BooleanInput
        fullWidth
        source="modify"
        label="Modify items - allows to update existing items via API when using this API key."
      />
      <BooleanInput
        fullWidth
        source="delete"
        label="Delete items - allows to delete items via API using this API key."
      />
      <TextField source="value" label="Api Key" />
    </SimpleForm>
  </Edit>
);

export const ApiKeyCreate = (props) => (
  <Create title="Create an Api key" {...props}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <BooleanInput
        fullWidth
        source="read"
        label="Read items - allows to access non-public items via API using this API key."
      />
      <BooleanInput
        fullWidth
        source="create"
        label="Create items - allows to create new items via API using this API key."
      />
      <BooleanInput
        fullWidth
        source="modify"
        label="Modify items - allows to update existing items via API when using this API key."
      />
      <BooleanInput
        fullWidth
        source="delete"
        label="Delete items - allows to delete items via API using this API key."
      />
    </SimpleForm>
  </Create>
);
