import { UserMenu, MenuItemLink } from "react-admin";
import IconRepeat from "@material-ui/icons/Repeat";

const MyUserMenu = (props) => {
  const role = localStorage.getItem("role");

  return (
    <UserMenu {...props}>
      {role === "Test" ||
      role === "Basic" ||
      role === "Essential" ||
      role === "Pro" ? (
        <MenuItemLink
          to="/orgs"
          primaryText="Switch Organization"
          leftIcon={<IconRepeat />}
        />
      ) : (
        <></>
      )}
    </UserMenu>
  );
};

export default MyUserMenu;
