const authProvider = {
  // authentication
  login: (params) => {
    const request = new Request("https://api.jsonstorage.net/v1/auth/google", {
      method: "POST",
      body: JSON.stringify({ tokenId: params.tokenId }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then((response) => {
        localStorage.setItem(
          "userProfile",
          JSON.stringify({
            id: response.id,
            email: response.email,
            fullName: response.firstName + " " + response.lastName,
            avatar: response.picture,
          })
        );
        localStorage.setItem("userId", response.id);
        localStorage.setItem("token", response.token);
        localStorage.setItem("role", response.role);
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: (params) => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("userProfile");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, fullName, avatar } = JSON.parse(
        localStorage.getItem("userProfile")
      );

      const organizationName = localStorage.getItem("organization.name");
      const organizationId = localStorage.getItem("organization.id");

      if(organizationId) {
        return Promise.resolve({ id: organizationId, fullName: organizationName });
      }

      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: (params) => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
