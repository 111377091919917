import React from "react";
import "./App.css";
import { Admin, Resource, Login } from "react-admin";
import jsonServerProvider from "./dataProvider";
import authProvider from "./authProvider";
import { PostList, PostEdit, PostCreate, PostIcon } from "./posts";
import { ApiKeyList, ApiKeyEdit, ApiKeyCreate, ApiKeyIcon } from "./ApiKeys";
import { IntentList, IntentEdit, IntentCreate, IntentIcon } from "./Intents";
import { StatsList, StatsIcon } from "./Stats";
import {
  OrganizationList,
  OrganizationEdit,
  OrganizationCreate,
  OrganizationIcon,
} from "./Organizations";
import GoogleLoginForm from "./login/GoogleLoginForm";
import MyLayout from "./MyLayout";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#dc840c",
    },
  },
});

const CustomLoginPage = () => (
  <Login backgroundImage="/img/bg-callout.jpg">
    <GoogleLoginForm />
  </Login>
);

// create the history
const history = createBrowserHistory();

// listen for a route change and record page view
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

// initialize GA
ReactGA.initialize("UA-193110502-1");

// record the first pageview because listen will not be called
ReactGA.pageview(window.location.pathname);

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Admin
        locale="en"
        layout={MyLayout}
        loginPage={CustomLoginPage}
        dataProvider={jsonServerProvider}
        authProvider={authProvider}
        history={history}
      >
        {(permissions) => [
          <Resource
            name="items"
            options={{ label: "Items" }}
            list={PostList}
            edit={PostEdit}
            create={PostCreate}
            icon={PostIcon}
          />,
          <Resource
            name="apikeys"
            options={{ label: "Api Keys" }}
            list={ApiKeyList}
            edit={ApiKeyEdit}
            create={ApiKeyCreate}
            icon={ApiKeyIcon}
          />,
          <Resource
            name="intents"
            options={{ label: "Intents" }}
            list={IntentList}
            edit={IntentEdit}
            create={IntentCreate}
            icon={IntentIcon}
          />,
          <Resource
          name="stats"
          options={{ label: "Stats" }}
          list={StatsList}
          icon={StatsIcon}
        />,
          (permissions === "Test" || permissions === "Pro" || permissions === "Essential") ? (
            <Resource
              name="orgs"
              options={{ label: "Organizations" }}
              list={OrganizationList}
              edit={OrganizationEdit}
              create={OrganizationCreate}
              icon={OrganizationIcon}
            />
          ) : null,
        ]}
      </Admin>
    </MuiThemeProvider>
  );
}

export default App;
