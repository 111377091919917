// in posts.js
import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
  UrlField,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  RadioButtonGroupInput,
} from "react-admin";
import BookIcon from "@material-ui/icons/Storage";
import { makeStyles } from "@material-ui/core/styles";
export const PostIcon = BookIcon;

const useIteratorStyle = makeStyles(() => ({
  form: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
  },
}));

export const PostList = (props) => (
  <List perPage={25} title="Items" {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Name" />
      <TextField source="excerpt" label="Content" />
      <BooleanField source="public" label="Public" />
    </Datagrid>
  </List>
);

const PostTitle = ({ record }) => {
  return <span>Editing item {record ? `"${record.name}"` : ""}</span>;
};

export const PostEdit = (props) => {
  const iteratorClasses = useIteratorStyle();

  return (
    <Edit title={<PostTitle />} {...props}>
      <SimpleForm>
        <TextInput source="name" fullWidth />
        <RadioButtonGroupInput
          source="itemType"
          label="Item Type"
          initialValue={0}
          choices={[
            { id: 0, name: "Content" },
            { id: 1, name: "Url Proxy (⭐)" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.itemType === 1 ? (
              <>
                <TextInput source="url" label="Url" fullWidth />
                <SelectInput
                  source="requestType"
                  label="Request Type"
                  initialValue={0}
                  choices={[
                    { id: 0, name: "GET" },
                    /*{ id: 1, name: "POST" },*/
                  ]}
                />
                <ArrayInput source="headers">
                  <SimpleFormIterator classes={iteratorClasses}>
                    <TextInput source="name" label="Name" helperText={false} />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <TextInput
                      source="value"
                      label="Value"
                      helperText={false}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            ) : (
              <TextInput
                multiline
                source="value"
                label="Content"
                fullWidth
                rowsMax="50"
              />
            )
          }
        </FormDataConsumer>

        <BooleanInput label="Public" source="public" />
        <UrlField source="getJson" fullWidth label="Get JSON" />
        <TextField
          source="updateJson"
          fullWidth
          label="Update JSON (use PUT verb)"
        />
        <TextField
          source="updateJson"
          fullWidth
          label="Patch JSON (use PATCH verb)"
        />

        <ArrayInput source="intents" label="Personalization">
          <SimpleFormIterator classes={iteratorClasses}>
            <ReferenceInput
              label="Intent"
              source="intentId"
              reference="intents"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ReferenceInput label="Item" source="itemId" reference="items">
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const PostCreate = (props) => {
  const iteratorClasses = useIteratorStyle();

  return (
    <Create title="Create an item" {...props}>
      <SimpleForm>
        <TextInput source="name" fullWidth />
        <RadioButtonGroupInput
          source="itemType"
          label="Item Type"
          initialValue={0}
          choices={[
            { id: 0, name: "Content" },
            { id: 1, name: "Url Proxy (⭐)" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.itemType === 1 ? (
              <>
                <TextInput source="url" label="Url" fullWidth />
                <SelectInput
                  source="requestType"
                  label="Request Type"
                  initialValue={0}
                  choices={[
                    { id: 0, name: "GET" },
                    /*{ id: 1, name: "POST" },*/
                  ]}
                />
                <ArrayInput source="headers">
                  <SimpleFormIterator classes={iteratorClasses}>
                    <TextInput source="name" label="Name" helperText={false} />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <TextInput
                      source="value"
                      label="Value"
                      helperText={false}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            ) : (
              <TextInput
                multiline
                source="value"
                label="Content"
                fullWidth
                rows="7"
              />
            )
          }
        </FormDataConsumer>
        <BooleanInput label="Public" source="public" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};
