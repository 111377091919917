import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import CallToActionIcon from '@material-ui/icons/BarChart';

export const StatsIcon = CallToActionIcon;

export const StatsList = (props) => (
    <List title="Stats" {...props}>
        <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="count" label="Count" />
        </Datagrid>
    </List>
);
