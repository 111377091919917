// in src/MyAppBar.js
import React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MyUserMenu from "./MyUserMenu";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  const role = localStorage.getItem("role");

  return (
    <AppBar
      {...props}
      style={{ backgroundColor: "#dc840c" }}
      userMenu={<MyUserMenu />}
    >
      <span>⚡ &nbsp;</span>
      <Typography variant="h6" id="react-admin-title" />
      {(role === "Test" || role === "Basic" || role === "Essential" || role === "Pro") ? (
        <span style={{paddingLeft: "10px"}}> Thank you for supporting JsonStorage! You can manage you plan <a target="_blank" rel="noreferrer" href="https://billing.stripe.com/login/28o7u26yv8PA7q87ss">here</a> </span>
      ) : (
        <a
          href="https://www.jsonstorage.net/#pricing"
          target="_blank"
          rel="noreferrer"
          style={{
            backgroundColor: "#0A95FF",
            color: "#FFFFFF",
            margin: "8px",
            padding: "4px 10px",
            borderRadius: "50px",
            textDecoration: "none",
          }}
        >
          Upgrade to support the project (plans start at just $1/mo and allow premium (⭐) features!)
        </a>
      )}
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
